<template>
  <v-container
    id="usermanagement-roles"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-currency-usd"
      :title="`${$t('sidebar.billing').toUpperCase()}`"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="submit"
          full-width
          :label="$t('search')"
          @keyup.enter="search"
        />
      </v-col>

      <v-client-table
        v-if="tableData.length"
        ref="table"
        :data="tableData"
        :columns="table.columns"
        :options="table.options"
      >
        <!--  Type-->
        <template
          slot="type"
          slot-scope="props"
        >
          <!-- Mode de paiment-->
          <!-- Payable par code de facturation( UBR)-->
          <template v-if="props.row.type === 1">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="green"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-book-open-variant
                </v-icon>
              </template>
              <span>UBR</span>
            </v-tooltip>
          </template>

          <!-- Carte de crédit-->
          <template v-if="props.row.type === 2">
            <template v-if="props.row.InscriptionPaiementId">
              <span class="mr-2">[{{ props.row.InscriptionPaiementId }}]</span>
            </template>

            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-credit-card
                </v-icon>
              </template>
              <span>{{ $t('order.Credit_card') }}</span>
            </v-tooltip>
          </template>
        </template>

        <!--  Numéro de contrat-->
        <template
          slot="Contrat.no"
          slot-scope="props"
        >
          <span>[ {{ props.row.Contrat.id }} ] {{ props.row.Contrat.no | phoneNumberFormat }}</span>
        </template>

        <!--  Action-->
        <template
          slot="action"
          slot-scope="props"
        >
          <!--  Edit-->
          <v-btn
            v-if="showEditFacture(props.row.type, props.row.paiementId, props.row.lot)"
            fab
            dark
            x-small
            color="success"
            @click="editView(props.row.id, props.row.type, true)"
          >
            <v-icon dark>
              mdi-pencil
            </v-icon>
          </v-btn>

          <!--  View-->
          <v-btn
            class="ml-2"
            fab
            light
            x-small
            color="white"
            @click="editView(props.row.id, props.row.type, false)"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>

        <!--  Cancel-->
        <template
          slot="cancel"
          slot-scope="props"
        >
          <span
            v-if="props.row.statut === -1"
            class="red--text"
          >
            {{ $t('contract.bill.Cancel') }}
          </span>

          <!--  Si pas annulé-->
          <v-btn
            v-else
            fab
            dark
            x-small
            color="red"
            :title="$t('Cancel')"
            @click="cancel(props.row.id)"
          >
            <v-icon dark>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
      </v-client-table>
    </base-material-card>

    <v-dialog
      v-model="dialogEdit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          :color="typeEdit === 1 ? 'green' : 'primary'"
        >
          <v-btn
            icon
            dark
            @click="dialogEdit = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  {{ typeEdit === 1 ? 'mdi-book-open-variant' : 'mdi-credit-card' }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('Billing_detail') }} {{ idEdit }}</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <facture-mensuelle-edit
          ref="factureMensuelleEdit"
          :edit="edit"
          :type-edit="typeEdit"
          @close-dialog="closeDialog"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// Component
  import FactureMensuelleEdit from '@/components/Client/Facture/FactureMensuelleEdit'
  // Service
  import FacturesService from '@/services/01Cell/FacturesService'
  // Other
  import {
  } from 'vee-validate'
  import Swal from 'sweetalert2'

  export default {
    name: 'OperationClientFacture',
    components: {
      FactureMensuelleEdit,
    },
    data () {
      return {
        submit: '',
        dialogEdit: false,
        edit: false,
        typeEdit: null,
        idEdit: null,
        tableData: [],
        table: {
          columns: ['action', 'id', 'periode', 'type', 'Client.displayName', 'Contrat.no', 'montant_total', 'frais_ajustement', 'cancel'],
          options: {
            sortable: ['id', 'periode', 'type', 'Client.displayName', 'Contrat.no', 'montant_total'],
            filterByColumn: false,
            filterable: ['id', 'periode', 'type', 'Client.displayName', 'Contrat.no', 'montant_total'],
            pagination: { chunk: 5 },
            perPage: 25,
            perPageValues: [10, 25, 50, 100],
            showChildRowToggler: false, // Si il y a des child row, sert pour cacher la colonne + pour afficher le child row. On utilise la fonction 'tableRowClick' plus bas
            texts: {
              count: this.$i18n.t('vueTables.texts.Showing') + ' {from} ' + this.$i18n.t('vueTables.texts.to') + ' {to} ' + this.$i18n.t('vueTables.texts.of') + ' {count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|{count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|' + this.$i18n.t('vueTables.texts.One_record'),
              first: this.$i18n.t('vueTables.texts.First'),
              last: this.$i18n.t('vueTables.texts.Last'),
              filter: this.$i18n.t('vueTables.texts.Filter') + ':',
              filterPlaceholder: this.$i18n.t('vueTables.texts.Search_query'),
              limit: this.$i18n.t('vueTables.texts.Records'),
              page: 'Page:',
              noResults: this.$i18n.t('vueTables.texts.No_matching_records'),
              filterBy: this.$i18n.t('vueTables.texts.Filter_by') + ' {column}',
              loading: this.$i18n.t('vueTables.texts.Loading'),
              defaultOption: this.$i18n.t('vueTables.texts.Select') + ' {column}',
              columns: this.$i18n.t('vueTables.texts.Columns'),
            },
            headings: {
              'Contrat.no': this.$i18n.t('Contract'),
              'Client.displayName': 'Client',
              montant_total: 'Total',
              cancel: this.$i18n.t('Cancel'),
            },
            columnsClasses: {
              action: 'VueTables-width-action-column text-left', // Voir dans le fichier 'style.scss'
              cancel: 'VueTables-width-action-column text-center', // Voir dans le fichier 'style.scss'
            },
            sortIcon: {
              is: 'fa-sort',
              base: 'fas',
              up: 'fa-sort-up',
              down: 'fa-sort-down',
            },
          },
        },
      }
    },

    computed: {
    },

    methods: {
      showEditFacture (type, paiementId, lot) {
        // Si carte de crédit et payer, on affiche pas le bouton edit
        if (type === 2 && paiementId) return false
        // Si ubr et payer, on affiche pas le bouton edit
        if (type === 1 && lot) return false
        return true
      },
      async search () {
        if (this.submit.length === 0) return
        // Start Spinner
        this.$loading.show()
        try {
          // Reset table
          this.tableData = []

          const tableData = await FacturesService.search(this.submit)
          this.tableData = tableData.map(item => {
            if (item.Client) item.Client.displayName = `[ ${item.Client.id} ] ${item.Client.prenom} ${item.Client.nom}`
            if (item.frais_ajustement) item.frais_ajustement = item.frais_ajustement.toFixed(2)
            return item
          })

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      editView (id, type, edit) {
        // Start Spinner
        this.$loading.show()
        // Show dialog
        this.dialogEdit = true

        this.typeEdit = type

        this.idEdit = id

        this.edit = edit

        this.$nextTick(() => {
          this.$refs.factureMensuelleEdit.resetSubmitForm(id)
        })
      },
      async cancel (id) {
        Swal.fire({
          title: 'Attention',
          text: `${this.$i18n.t('contract.swal.text.Change_bill_status')} [${id}]`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Yes'),
          cancelButtonText: this.$i18n.t('No'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              await FacturesService.update({ statut: -1 }, id)
              // Update facture
              const findIndex = this.tableData.findIndex(x => x.id === id)
              this.tableData[findIndex].statut = -1
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      closeDialog () {
        // Show dialog
        this.dialogEdit = false

        // Update la table
        this.search()
      },
    },
  }
</script>
